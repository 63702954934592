// @flow
import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { get, find } from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import useTree from './hooks';
import { Tab } from './tab';
import styles from './Tree.scss';

const Tree = (props: {session: { isEdit: boolean }}) => {
  const { pages, buffers, isLogin, session, handleClick, collapsed, page } = useTree(props);
  const { pathname } = useLocation();
  const [tabs, setTabs] = React.useState(Object.create(null));

  React.useEffect(() => {
    // группировка страниц по табам
    if (pages?.length === 0 || Object.keys(tabs).length > 0) return;

    const tabsTemp = Object.create(null);

    for (const page of pages) {
      if (!tabsTemp[page.tab]) {
        tabsTemp[page.tab] = [];
      }

      tabsTemp[page.tab].push(page);
    }

    setTabs(tabsTemp);
  }, [pages, tabs]);

  const renderPages = (pages) => {
    return (
      <List className={styles.Root}>
        {
          pages.filter(p => !isLogin ? p.isExternal : p).map((item) => {
            const buffered = isLogin && get(buffers, `[${get(item, 'id')}]`);
            const current = buffered || item;
            const isSaved = !buffered;
            return (
              <ListItem
                key={get(current, 'id')}
                className={styles.ListItem}
              >
                <ListItemText
                  className={classNames(
                    styles.ListItemText,
                    {
                      [styles.ListItemTextNotSaved]: isLogin && !isSaved,
                      [styles.ListItemTextSelected]: get(current, 'id') === get(page, 'id')
                    }
                  )}
                  primary={(
                    <>
                      {get(current, 'title')}
                      {
                        ['admin'].indexOf(get(session, 'role')) >=0
                        && get(current, 'isLocked')
                        && get(current, 'user.id') !== get(session, 'id')
                        && (
                          <Tooltip title="Locked by other user" placement="top">
                            <LockIcon className={styles.LockIcon} />
                          </Tooltip>
                        )
                      }
                      {(!isSaved && props.session.isEdit)  && <Tooltip title="Unpublished changes" placement="top"><i /></Tooltip>}
                    </>
                  )}
                  onClick={handleClick(current)}
                />
                {
                  get(current, 'sections', []).map((sub) => get(sub, 'title') && (
                    <Collapse
                      key={get(sub, 'id')}
                      in={!!find(collapsed, { id: get(current, 'id') })}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        <ListItem
                          className={styles.ListSubItem}
                        >
                          <ListItemText className={classNames({
                            [styles.ListSubItemText]: true,
                            [styles.Selected]: get(sub, 'link') === pathname,
                          })} primary={get(sub, 'title')} onClick={handleClick(sub)} />
                        </ListItem>
                      </List>
                    </Collapse>
                  ))
                }
              </ListItem>
            );
          })
        }
      </List>
    )
  }

  const renderTabs = () => {
    const tabsTemp = Object.keys(tabs);
    let pages = [];
    let foundPage = null;

    return (
      <List className={styles.Root}>
        {tabsTemp.map((tab) => {
          pages = tabs[tab];
          foundPage = pages.find((p) => pathname.includes(p.link));
          return (
            <Tab tab={tab} key={tab} pages={renderPages(tabs[tab])} active={foundPage?.tab === tab} />
          )
        })}
      </List>
    )
  }

  return renderTabs();
};

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  buffers: get(state, 'boards.buffers', {}),
  session: get(state, 'session', {}),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Tree);
