import classNames from "classnames";
import { get } from "lodash";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { setActiveTab } from "../../services/tab/tab";
import styles from "./TableMenu.scss";

const Tab = ({ tab }): JSX.Element | null => {
  const dispatch = useDispatch();
  const activeTab = useSelector(state => get(state, 'tab'));
  const isEdit = useSelector(state => get(state, 'session.isEdit', false));
  const className = classNames({
    [styles.Tab]: true,
    [styles.isEdit]: isEdit
  });

  if (isEdit) {
    return null;
  }

  if (!tab?.title && !isEdit) {
    return null;
  }

  if (!tab?.title && isEdit) {
    return (
      <div className={styles.Empty}>+Table Title</div>
    );
  }

  const setActive = (tab) => {
    dispatch(setActiveTab(tab));
  }

  return (
    <NavLink
      to={`/wiki?tab=${tab?.title?.toLowerCase()}`}
      onClick={() => setActive(tab)}
      className={classNames({
      [className]: true,
      [styles.Active]: activeTab?.title === tab?.title
    })}>
      {tab?.title}
    </NavLink>
  )
};

export default memo(Tab);
