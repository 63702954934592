//Libraries
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arrayMove, SortableContainer } from "react-sortable-hoc";
import { FieldArray, change } from "redux-form";
import classNames from "classnames";
import {v4 as uuidv4} from 'uuid';
import { find, get } from "lodash";

//Components
import { Box, CardActions } from "@material-ui/core";
import { H4 } from '@components/Typography';
import Button from "../../components/Button";
import { EditTab } from "./EditTab";
import EditModeForm from "./EditModeForm";

//Actions
import { updateTabs } from "../../services/boards/actions";

//Icons
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import styles from "./TableMenu.scss";

const SortableList = SortableContainer(({items, onUpdateTabs, editMode, onChange}) => {
  return (
    <ul className={styles.SortableList}>
      {items.map((value, index) => (
          <EditTab
            key={value.id}
            index={index} tab={value}
            {...items.get(index)}
            tabIndex={index}
            onUpdateTabs={onUpdateTabs}
            editMode={editMode}
            onChange={onChange}
          />
        ))
      }
    </ul>
  );
});

const EditModeMemo = ({ children, isEdit, mainBannerData, tabs, handleUpdatePage }) => {
  const { content } = useSelector(state => find(get(state, 'boards.list.sys.pages'), { name: 'main_banner' }));
  const [editTabs, setEditTabs] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const dispatch = useDispatch();

  const onUpdateTabs = (tabs) => {
    handleUpdatePage({
      variables: {
        id: get(mainBannerData, 'id'),
        description: get(mainBannerData, 'description'),
        content: JSON.stringify({
          ...content,
          tabs
        })
      }
    });
  };

  const addTabCard = () => {
    if (editTabs.length !== tabs.length) {
      editMode && setEditTabs([...editTabs, createTab()]);
      // onUpdateTabs();
    }
  };

  useEffect(() => {
    setEditTabs(tabs?.filter(tab => tab));
  }, [tabs]);

  if (!isEdit) {
    return children;
  }

  return (
    <Box className={styles.EditMode} width={1}>
      <H4 className={styles.Title}>
        <span>Table Menu</span>
        {/* <EditModeButton {...{editMode, setEditMode}}/> */}
      </H4>
      <div className={styles.Tabs}>
        {children}
      </div>
      <CardActions
        className={classNames({
          [styles.AddButton]: true,
          [styles.Disabled]: editTabs?.length === tabs?.length || !editMode
        })}
        onClick={addTabCard}
      >
        <Button
          size="small"
          startIcon={<PlusIcon />}
          disabled={editTabs?.length === tabs?.length || !editMode}
        >
          Add new tab
        </Button>
      </CardActions>
      <EditModeForm>
        <FieldArray
          name={'items'}
          component={Tabs}
          tabs={editTabs}
          onUpdateTabs={onUpdateTabs}
          editMode={editMode}
          onChange={(e) => {
            dispatch(
              change(
                'editTabs',
                e.target.name.replace('.title', `.link`),
                `${window.location.origin}${window.location.pathname}?tab=${e.target.value.toLowerCase()}`)
            );
          }}
        />
      </EditModeForm>
    </Box>
  )
};

const createTab = () => {
  return {
    id: uuidv4(),
    title: null,
    link: null
  }
}

const Tabs = ({ fields, tabs, onUpdateTabs, editMode, onChange }) => {
  const dispatch = useDispatch();
  const initFields = useCallback(() => Array.from(tabs ?? [], item => fields.push(item)), [tabs]);
  const newTabs = useMemo(() => renderTabs(tabs), [tabs]);

  const onSortEnd = ({oldIndex, newIndex}) => {
    dispatch(updateTabs((arrayMove(newTabs, oldIndex, newIndex))));
    onUpdateTabs(arrayMove(newTabs, oldIndex, newIndex));
  };

  function renderTabs(tabs) {
    if (tabs) {
      switch (tabs.length) {
        case 1:
          return [...tabs, null, null];
        case 2:
          return [...tabs, null];
        case 3:
        default:
          return [...tabs];
      }
    }
  }

  useEffect(() => {
    fields.removeAll();
    initFields();
  }, [tabs]);

  return <SortableList onChange={onChange} items={fields} onSortEnd={onSortEnd} useDragHandle onUpdateTabs={onUpdateTabs} editMode={editMode}/>
};

const EditModeButton = ({ editMode, setEditMode }) => {
  return (
    <label
      className={styles.EditModeButton}
      htmlFor="edit_mode_tabs_checkbox"
    >
      <input
        id="edit_mode_tabs_checkbox"
        name="edit_mode"
        type="checkbox"
        checked={editMode}
        onChange={() => setEditMode(editMode => !editMode)}
      />
      <i />
    </label>
  );
};

export const EditMode = memo(EditModeMemo);
