// @flow
import React, { Element, useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect , useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {change, reduxForm} from 'redux-form';
import { compose } from 'recompose';
import { get, find } from 'lodash';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableMenu from '../Table Menu';
import Modal from '@components/Modal';
import Form, { Input, TextArea } from '@components/Form';
import Page from '@containers/Page';
import { H1, Subtitle } from '@components/Typography';
import Button from '@components/Button';
import { Card, VARIANT, TYPE } from '@components/Card';
import Search from '../Search/Search';
import EditMode from '../EditMode/EditMode';
import { Actions } from '../../components/Form';
import { DownloadAssets } from '../DownLoadAssets';
import { requiredField } from '../../utils/validators';
import useMain from './hooks';
import SaveIcon from '@material-ui/icons/Save';
import { ReactComponent as GreenCircle } from '@assets/icons/green_circle.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { setActiveTab } from '../../services/tab/tab';
import styles from './Main.scss';

type MAIN_PROPS_TYPES = {};

const Main = (props: MAIN_PROPS_TYPES): Element<Page> => {
  const dispatch = useDispatch();
  const activeTab = useSelector(state => get(state, 'tab'));
  const tabs = useSelector(state => get(state, 'boards.list.sys.mapped[11].tabs', []).filter(tab => tab).map(tab => tab.title));
  const {
    board,
    pages,
    isEdit,
    isLogin,
    mainBannerData,
    isModal, setIsModal,
    editButton, setEditButton,
    handleCreate,
    handleSubmit,
    handleUpdatePage,
    anyTouched,
    formValues
  } = useMain(props);
  const [filteredPagesByTab, setFilteredPagesByTab] = useState(pages);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab');
  const history = useHistory();

  React.useEffect(() => {
    if (!currentTab) return;

    const foundTab = mainBannerData?.tabs?.find((t) => t?.title?.toLowerCase() === currentTab.toLowerCase());

    if (!foundTab) return;

    dispatch(setActiveTab(foundTab));
    dispatch(change('searchResult', 'search', ''));
  }, [currentTab, mainBannerData]);

  React.useEffect(() => {

    if (currentTab) return;

    if (!activeTab) {
      const tab = mainBannerData?.tabs?.[0]|| null;

      if (tab) {
        dispatch(setActiveTab(tab));

        history.push({
          search: `tab=${tab.title.toLowerCase()}`,
        });
      }
    }

    dispatch(change('searchResult', 'search', ''));
  }, [mainBannerData, activeTab, currentTab, history]);

  useEffect(() => {
    if (activeTab?.title?.length && tabs.length > 1) {
      setFilteredPagesByTab(pages.filter(t => t.tab === activeTab?.title).filter(p => !isLogin ? p.isExternal : p));
    }
    else setFilteredPagesByTab(pages.filter(p => !isLogin ? p.isExternal : p));
  }, [pages, activeTab, isLogin]);

  return (
    <Page
      classes={{
        root: styles.Root,
        content: styles.Content,
      }}
      isLogin={isLogin}
      isHasSandwich
      data-edit={isEdit}
      data-login={isLogin}
    >
      {/* <Search classnames={{root: styles.Search}} /> */}
      <Box
        className={styles.Banner}
      >
        <H1 className={styles.Title}>
          Kaspersky
          {' '}
          <br data-lg />
          Brand
          {' '}
          <br data-sm />
          Guide
        </H1>
        {
          !isEdit &&
          <Search isLogin={isLogin} classnames={{root: styles.SearchLogin}} />
        }
        <Box className={styles.Description}>
          {
            isEdit ? (
              <Form onSubmit={handleSubmit}>
                <TextArea
                  classes={{
                    base: styles.TextAreaBase,
                    textArea: styles.TextArea,
                  }}
                  name="description"
                  placeholder="Enter desctiption"
                  validate={[requiredField]}
                  maxLength={300}
                />
                <Modal
                  classes={{
                    content: styles.ModalContent
                  }}
                  title="Edit button"
                  onClick={(event) => {}}
                  onClose={(event) => { setIsModal(false); }}
                  open={isModal}
                  maxWidth="md"
                  fullWidth
                >
                  <Input
                    classes={{
                      base: styles.InputBase
                    }}
                    name={`${editButton}ButtonTitle`}
                    placeholder="Enter button name"
                    type="text"
                    validate={[requiredField]}
                    maxLength={25}
                  />
                  <Input
                    classes={{
                      base: styles.InputBase
                    }}
                    name={`${editButton}ButtonLink`}
                    placeholder="Enter button link"
                    type="text"
                    maxLength={100}
                  />
                  <Button
                    className={styles.ModalButton}
                    variant="contained"
                    onClick={() => {
                      setIsModal(false);
                    }}
                  >
                    Ok
                  </Button>
                </Modal>
              </Form>
            ) : (
              <Subtitle className={styles.Subtitle}>
                {get(mainBannerData, 'description')}
              </Subtitle>
            )
          }
          <Actions classes={{root: styles.Actions}}>
          <DownloadAssets setIsModal={setIsModal} setEditButton={setEditButton} {...props}/>
            {
              (isEdit || get(mainBannerData, 'content.blocks.secondButton.link') && isLogin) && (
                <Button
                  variant="outlined"
                  fullWidth
                  className={classNames({
                    [styles.ButtonSecondary]: true,
                    [styles.isEdit]: isEdit
                  })}
                  startIcon={<GreenCircle />}
                  onClick={
                    isEdit
                      ? () => {
                      setEditButton('second');
                      setIsModal(true);
                    }
                      : () => {
                        window.location = get(mainBannerData, 'content.blocks.secondButton.link');
                      }
                  }
                >
                  {
                    isEdit
                      ? get(formValues, 'secondButtonTitle') ?? 'Enter button name'
                      : get(mainBannerData, 'content.blocks.secondButton.title')
                  }
                </Button>
              )
            }
            {
              isEdit && anyTouched && (
                <IconButton
                  color="primary"
                  className={classNames(
                    styles.Button,
                    styles.ButtonSave,
                  )}
                  onClick={(): void => handleUpdatePage({
                    variables: {
                      id: get(mainBannerData, 'id'),
                      description: get(formValues, 'description'),
                      content: JSON.stringify({
                        blocks: {
                          firstButton: {
                            title: get(formValues, 'firstButtonTitle', ''),
                            link: get(formValues, 'firstButtonLink', '')
                          },
                          secondButton: {
                            title: get(formValues, 'secondButtonTitle', ''),
                            link: get(formValues, 'secondButtonLink', '')
                          },
                          thirdButton: {
                            title: get(formValues, 'thirdButtonTitle', ''),
                            link: get(formValues, 'thirdButtonLink', '')
                          }
                        },
                        tabs: get(mainBannerData, 'tabs'),
                        entityMap: {}
                      })
                    }
                  })}
                >
                  <SaveIcon />
                </IconButton>
              )
            }
          </Actions>
        </Box>
      </Box>
      <TableMenu {...{...props, handleUpdatePage}}/>
      <Grid
        container
        className={styles.Items}
        spacing={3}
      >
        {
          isEdit && isLogin
            ?
              (
                <>
                  <CardActions
                    className={styles.AddNewCard}
                    onClick={() => handleCreate({
                      variables: {
                        name: '',
                        title: '',
                        isExternal: false,
                        tab: null
                      }
                    })}
                  >
                    <Button
                      size="small"
                      startIcon={<PlusIcon />}
                    >
                      Add new card
                    </Button>
                  </CardActions>
                  <EditMode />
                </>
              )
            :
              (
                filteredPagesByTab.map((item, index) => (
                  <Grid
                    className={styles.Item}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    key={get(item, 'id')}
                  >
                    <Card
                      classes={{
                        root: styles.CardRoot,
                        media: styles.CardMedia,
                        content: styles.CardContent,
                        title: styles.CardTitle,
                        description: styles.CardDescription,
                      }}
                      variant={VARIANT.VERTICAL}
                      type={TYPE.LINK}
                      data={item}
                      index={index}
                    />
                  </Grid>
                ))
              )
        }
      </Grid>
    </Page>
  )
};

const MainForm = reduxForm({
  form: 'mainBanner',
  touchOnChange: true,
})(Main);

const mapStateToProps = (state: Object): Object => ({
  formValues: get(state, 'form.mainBanner.values'),
  pages: get(state, 'boards.list.wiki.pages', []),
  board: get(state, 'boards.list.wiki'),
  mainBannerData: find(get(state, 'boards.list.sys.pages'), { name: 'main_banner' }),
  isLogin: !!get(state, 'session.token', false),
  isEdit: get(state, 'session.isEdit', false),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(MainForm);
