// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import LongRead from '@views/Builder/containers/LongRead';
import Tree from '@views/Builder/containers/Tree';
import OrderSectionsModal from '@containers/modals/sections/Order';
import EditSectionModal from '@containers/modals/sections/Edit';
import DeleteSectionModal from '@containers/modals/sections/Delete';
import Button from '@components/Button';
import Box from '@material-ui/core/Box';
import Page from '@containers/Page';
import styles from './Builder.scss';
import { ReactComponent as CancelIcon } from '@assets/icons/cross.svg';
import { ReactComponent as WhiteCheckIcon} from '@assets/icons/white_check.svg';
import { updateBoards, updateBuffers } from '@services/boards';
import { updateSession } from '@services/session';
import BuilderContext from './context';
import useBuilderPage from './hooks';

const BuilderPage = (props) => {
  const usedBuilderPage = useBuilderPage(props);
  const {
    page,
    pages,
    isLogin,
    handlePublish,
    buffers,
    handleCancel,
    handleMode
  } = usedBuilderPage;
  const isSaved = !get(buffers, `[${get(page, 'id')}]`);
  const isLocked = get(page, 'isLocked');
  const { isEdit } = props;

  return (
    <BuilderContext.Provider
      value={usedBuilderPage}
    >
      <Page
        classes={{
          content: styles.Content
        }}
        onMode={handleMode}
        isHasSandwich
        menuItems={{ vertical: pages.filter(p => !isLogin ? p.isExternal : p) }}
        actionPanel={isLogin && !isSaved && isLocked && isEdit && (
          <>
            <Button
              variant="outlined"
              color="secondary"
              className={styles.Button}
              startIcon={<CancelIcon />}
              onClick={handleCancel}
            >
              Cancel update
            </Button>
            <Button
              variant="contained"
              className={styles.Button}
              startIcon={<WhiteCheckIcon />}
              onClick={handlePublish}
            >
              Publish update
            </Button>
          </>
        )}
      >
        {!isLogin && !page?.isExternal ?
          null :
          <Box display="flex" flexDirection="row">
           <Tree />
           <LongRead />
         </Box>}
      </Page>
      <OrderSectionsModal />
      <EditSectionModal />
      <DeleteSectionModal />
    </BuilderContext.Provider>
  )
};

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  buffers: get(state, 'boards.buffers', {}),
  isEdit: get(state, 'session.isEdit', false),
  session: get(state, 'session', {}),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  updateBuffers: (data, isHard) => dispatch(updateBuffers(data, isHard)),
  updateSession: (data) => dispatch(updateSession(data))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(BuilderPage);
