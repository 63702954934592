import * as React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { ReactComponent as ArrowUpIcon } from '@assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down.svg';
import styles from './Tree.scss';
import classNames from 'classnames';

export function Tab({ pages, tab, active = true }) {
  const [open, setOpen] = React.useState(true);

  const renderIcon = () => {
    if (open) return <ArrowUpIcon />;

    return <ArrowDownIcon />
  }

  const toggleTab = () => {
    setOpen(!open);
  }

  return (
    <ListItem className={styles.ListItem}>
      <ListItemText
        onClick={toggleTab}
        className={classNames(styles.tab, {[styles.tab_active]: active})}
        primary={<div style={{ marginLeft: -24 }}>{renderIcon()} {tab}</div>}
      />
      {open ? pages : null}
    </ListItem>
  )
}
